/**
 * 
 * @param {ScoreMap[string]['myAnswer']} myAnswer 
 * @param {ScoreMap[string]['answer']} answer 
 * @param {ScoreMap[string]['score']} score 
 * @param {boolean} isNeedEvery 
 * @returns 
 */
export const calcScore = (myAnswer, answer, score, isNeedEvery) => {
  if (Array.isArray(myAnswer) && Array.isArray(answer)) {
    console.log(myAnswer && myAnswer.map && myAnswer.map(item => item), answer && answer.map && answer.map(item => item), '对吗?')
    let rightCount = 0
    // 必须每个都对, 例如多选题
    if (isNeedEvery) {
      let isOk = answer.every(item => myAnswer.includes(item)) && answer.length === myAnswer.length
      if (isOk) {
        rightCount = answer.length
      } else {
        rightCount = 0
      }
    } else {
      answer.forEach((item, index) => {
        // |||表示'或'的逻辑
        if (item.includes('|||')) {
          const arr = item.split('|||')
          if (arr.includes(myAnswer[index])) {
            rightCount++
          }
        } else {
          if (item === myAnswer[index]) {
            rightCount++
          }
        }
      })
    }
    console.log((rightCount / answer.length) * score, '(rightCount / answer.length) * score')
    return (rightCount / answer.length) * score
  } else {
    return (answer === myAnswer) ? score : 0
  }
}

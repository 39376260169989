import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { listenStorageMsg } from "../utils/message"
import { setGlobalBread, setGlobalRoutes, setRouterSetting } from "../store/routeSlice"
import { me } from "../request/user"
import { setProfile, setRequestMe, setUserType } from "../store/userSlice"
import { ManagerType, StudentType, TeacherType, routerSettings } from "../settings/ProComponents/ProLayoutSetting"
import { getRoutes } from "../router"

// 倒计时hook
export const useCountdown = (num = 10) => {
  const seconds = Math.round(Math.abs(num)) || 10
  const [count, setCount] = useState(seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (count > 1) {
        setCount(prev => prev - 1)
      } else {
        clearTimeout(timer)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [count])
  return count
}

// 定时循环hook
export const useInterval = (num = 10) => {
  const seconds = Math.round(Math.abs(num)) || 10
  const [count, setCount] = useState(seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (count > 1) {
        setCount(prev => prev - 1)
      } else {
        setCount(num)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [count])
  return count
}

// 逐题练习下判断是否答题正确
export const useAnswer = () => {
  const scoreMap = useSelector(state => state.score.scoreMap)
  const indexProIdMap = useSelector(state => state.score.indexProIdMap)
  const currentIndex = useSelector(state => state.score.currentIndex)
  // 是否已经完成回答
  const isFinish = useMemo(() => {
    try {
      const quesInfo = scoreMap[indexProIdMap[currentIndex]]
      if (quesInfo.isFinish) {
        return quesInfo.isFinish
      } else {
        return false
      }
    } catch(e) {
      return false
    }
  }, [scoreMap, currentIndex])
  // 考生答案
  const myAnswer = useMemo(() => {
    try {
      const quesInfo = scoreMap[indexProIdMap[currentIndex]]
      if (quesInfo.myAnswer) {
        return quesInfo.myAnswer
      }
      return ''
    } catch(e) {
      return true
    }
  }, [scoreMap, currentIndex])
  return [isFinish, myAnswer]
}

// storage消息
export const useStorageMsg = (cb, deps = []) => {
  useEffect(() => {
    let fn = listenStorageMsg((e) => {
      localStorage.removeItem('@@' + e.type)
      cb && cb(e)
    })
    return () => fn()
  }, deps)
}

// 强制刷新
export const useForceupdate = () => {
  const [,forceUpdate] = useState({})
  return () => forceUpdate({})
}

// 设置全局面包屑
export const useCrumb = (arr) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setGlobalBread(arr))
    return () => dispatch(setGlobalBread([]))
  }, [])
}


// 登录逻辑
export const useLogin = () => {
  const dispatch = useDispatch()
  return () => {
    return me().then(({data}) => {
      dispatch(setProfile(data))
      // 获取用户角色
      let userType = null
      // 超级管理员
      if (data.is_admin) {
        userType = ManagerType
      // 如非超级管理员
      } else if (data && data.campus_data && data.campus_data.user_type) {
        let _userType = data.campus_data.user_type
        // 如果是老师或者校区管理员则按照老师角色处理
        if (_userType === TeacherType || _userType === ManagerType) {
          userType = TeacherType
        // 学生角色
        } else if (_userType === StudentType) {
          userType = StudentType
        }
      }
      if (userType !== null) {
        window.localStorage.setItem('userType', userType)
      } else {
        // message.error('角色异常')
      }
      
      dispatch(setProfile(data))
      const routes = getRoutes(userType)
      dispatch(setGlobalRoutes(routes))
      dispatch(setUserType(userType))
      dispatch(setRequestMe(false))
      dispatch(setRouterSetting(routerSettings.find(item => item.routeName === userType)))
    }).catch(e => {
      console.log('e', e)
    })
  }
}
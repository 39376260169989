import { api } from './api'

export const getSubjects = ({sort}) => {
  let other = ''
  if (sort) {
    other = `?sort=${sort}`
  }
  return api.get(`/subjects/${other}`)
}

export const getSubjectTypes = ({sort}) => {
  let other = ''
  if (sort) {
    other = `?sort=${sort}`
  }
  return api.get(`/subjects/all/${other}`)
}

export const createSubject = params => {
  return api.post('/subjects/', params)
}

export const deleteSubject = id => {
  return api.delete(`/subjects/${id}/`)
}

export const renameSubject = (id, params) => {
  return api.put(`/subjects/${id}/`, params)
}

export const getCategory = id => {
  return api.get(`/subjects/${id}/category/`)
}

export const addCategory = (id, params) => {
  return api.post(`/subjects/${id}/category/`, params)
}

export const deleteCategory = (id, category_id) => {
  return api.delete(`subjects/${id}/category/?category_id=${category_id}`)
}

// 科目排序
export const sortSubjects = (data) => {
  return api.post('subjects/sort/', data)
}


// 科目详情
export const getSubjectsDetail = (id) => {
  return api.get(`subjects/${id}`)
}
export const ManagerType = 'admin'
export const StudentType = 'student'
export const TeacherType = 'teacher'

const StudentRouterSetting = {
  routeName: StudentType,
  route: {
    path: '/',
    routes: [
      {
        path: '/todo',
        name: '待办',
        icon: 'CarryOutFilled',
        component: '../pages/Todo',
      },
      {
        path: '/group',
        name: '团队',
        icon: 'CrownFilled',
        component: '../pages/Group',
        routes: [],
      },
      {
        path: '/record',
        name: '记录',
        icon: 'TabletFilled',
        component: './ListTableList',
        routes: [],
      },
    ],
  },
  location: {
    pathname: '/',
  }
};

const TeacherRouterSetting = {
  routeName: TeacherType,
  route: {
    path: '/',
    routes: [
      {
        path: '/todo',
        name: '待办',
        icon: 'CarryOutFilled',
        component: '../pages/Todo',
      },
      {
        path: '/problemsCenter',
        name: '公共题库中心',
        icon: 'DatabaseFilled',
        routes: []
      },
      {
        icon: 'TabletFilled',
        path: '/group',
        name: '团队',
        component: './ListTableList',
        routes: [],
      },
    ],
  },
  location: {
    pathname: '/',
  }
};

const ManagerRouterSetting = {
  routeName: ManagerType,
  route: {
    path: '/',
    routes: [
      {
        path: '/todo',
        name: '待办',
        icon: 'CarryOutFilled',
        component: '../pages/Todo',
      },
      {
        path: '/problemsCenter',
        name: '公共题库中心',
        icon: 'DatabaseFilled',
        routes: []
      },
      {
        path: '/manageSubject',
        name: '科目管理',
        icon: 'SettingFilled',
      }
    ],
  },
  location: {
    pathname: '/',
  }
};

export const routerSettings = [
  StudentRouterSetting,
  TeacherRouterSetting,
  ManagerRouterSetting
]
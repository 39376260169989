import { createSlice } from '@reduxjs/toolkit'
import { defaultRoutes } from '../utils/routersFilter'

export const moduleSlice = createSlice({
  name: 'route',
  initialState: {
    routes: defaultRoutes,
    routerSetting: {},
    pathname: window.location.pathname,
    globalBread: []
  },
  reducers: {
    setGlobalRoutes: (state, action) => {
      state.routes = action.payload
    },
    setRouterSetting: (state, action) => {
      state.routerSetting = action.payload
    },
    setPathname: (state, action) => {
      state.pathname = action.payload
    },
    setGlobalBread: (state, action) => {
      state.globalBread = JSON.stringify(action.payload)
    },
  },
})

export const {
  setGlobalRoutes,
  setRouterSetting,
  setPathname,
  setGlobalBread
} = moduleSlice.actions;

export default moduleSlice.reducer;

import { useCallback, useEffect, useState } from "react";
import { getTeam, syncData } from "../../request/teams";
import { Table, Button, message, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { TeacherType, StudentType } from "../../settings/ProComponents/ProLayoutSetting";
import "./style.scss"
import { TeamLabel } from "../../components/teamLabel";
import { pageSize } from "../Todo/common";
import { sortFn } from "../../utils/common";

const Group = () => {
  // 获取userType
  const userType = useSelector(state => state && state.user && state.user.userType)

  // 搜索
  const [keyword, setKeyword] = useState()
  const navigate = useNavigate()
  const [pageNum, setPageNum] = useState(1)
  const [sort, setSort] = useState(null)
  const [page_size, setPageSize] = useState(pageSize)
  // 操作按钮
  const Btns = ({data}) => {
    // 学生
    if (userType === StudentType) {
      return <>
        <div className="group__btn" onClick={() => toExercise(data)}>练习</div>
        <div className="group__btn" onClick={() => toExam(data)}>考试</div>
      </>
    } else if (
      // 老师
      userType === TeacherType) {
      return <>
        <div className="group__btn" onClick={() => toStudents(data)}>学员列表</div>
        <div className="group__btn" onClick={() => toExercise(data)}>练习管理</div>
        <div className="group__btn" onClick={() => toExam(data)}>考试管理</div>
      </>
    }
  }
  const columns = [
    {
      title: '团队编号',
      key: 'code',
      dataIndex: 'code',
      width: 120,
      sorter: true
    },
    {
      title: '团队名称',
      key: 'name',
      dataIndex: 'name',
      width: 120,
      render(_, data) {
        return (
         <TeamLabel cover={data.cover} name={data.name} />
        )
      },
      sorter: true
    },
    {
      title: '授课教师',
      key: 'teachers',
      dataIndex: 'teachers',
      width: 120,
      sorter: true
    },
    {
      title: '团队学员',
      key: 'student_num',
      dataIndex: 'student_num',
      width: 120,
      sorter: true
    },
    {
      title: '练习',
      key: 'exercise_num',
      dataIndex: 'exercise_num',
      width: 120,
      sorter: true
    },
    {
      title: '考试',
      key: 'examination_num',
      dataIndex: 'examination_num',
      width: 120,
      sorter: true
    },
    {
      title: '操作',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render (_, data) {
        return <>
        <div className="group__action">
          <Btns data={data}/>
        </div>
        </>
      }
    }
  ]
  const [data, setData] = useState({})
  // 获取团队数据
  const getTeamXhr = () => {
    getTeam({page_size, page: pageNum, search: keyword, sort}).then(res => {
      if (res.status === 200) {
        console.log(res.data, 'res.data???')
        setData(res.data)
      }
    })
  }
  useEffect(() => {
    getTeamXhr()
  }, [pageNum, keyword, page_size, sort])

  // 跳转练习
  const toExercise = (data) => {
    console.log(data)
    navigate(`/exercise/${data.id}`)
  }
  // 跳转考试
  const toExam = (data) => {
    navigate(`/exam/${data.id}`)
  }

  // 跳转学生列表
  const toStudents = (data) => {
    navigate(`/studentlist/${data.id}`)
  }

  // 同步数据
  const [messageApi, context] = message.useMessage()
  const updateData = () => {
    syncData().then(res => {
      if (res.status === 200) {
        messageApi.success('同步成功')
        getTeamXhr()
      }
    })
  }

  // 翻页
  const handlePage = (...args) => {
    const sort = sortFn(args)
    if (sort !== false) {
      setSort(sort)
    }
    const page = args[0]
    setPageNum(page.current)
  }

  // 搜索
  const handleSearch = useCallback((e) => {
    const keyword = e.target.value
    setPageNum(1)
    setKeyword(keyword)
  },[keyword])
  const showSizeChange = useCallback((page, page_size) => {
    setPageNum(page)
    setPageSize(page_size)
  }, [])
  return (
    <div className="group">
        <div className="group__top">
          <Input className="group__input" placeholder="团队名称/授课老师" onPressEnter={handleSearch}/>
          {userType === TeacherType && <Button onClick={updateData}>同步数据</Button>}
        </div>
        <Table onChange={handlePage}
          pagination={
          {
            defaultPageSize: pageSize,
            total: data.count,
            current: pageNum,
            showSizeChanger: true,
            onShowSizeChange: showSizeChange
          }
          }  columns={columns} dataSource={data.results}/>
        {context}
      </div>
  );
}

export default Group
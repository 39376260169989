// 转换为日期格式
export const strToDate = (str) => {
  if (str && new Date(str)) {
    const format = new Date(str).toLocaleDateString()
    return format.replace(/\//g, '-')
  } else {
    return '-'
  }
}

export const strToDateTime = (str) => {
  if (str && new Date(str)) {
    const date = new Date(str)
    const dateStr = date.toLocaleDateString()
    const timeStr = date.toLocaleTimeString()
    return dateStr.replace(/\//g, '-') + ' ' + timeStr
  } else {
    return '-'
  }
}

export const secToMin = (sec) => {
  if (!isNaN(sec)) {
    if ((sec / 60) < 1) {
      return sec + '秒'
    } else {
      let min = sec / 60
      return min && (min.toFixed(2) + '分钟')
    }
  } else {
    return '-'
  }
}

export const secToTimes = (seconds) => {
  let _seconds = seconds
  const obj = {
    hours: 0,
    minutes: 0,
    seconds: 0
  }
  // 小时
  if (_seconds / 3600 >= 1) {
    obj.hours = Math.floor(seconds / 3600)
    _seconds = _seconds % (obj.hours * 3600)
  } else {
    obj.hours = 0
  }
  // 分钟
  if (_seconds / 60 >= 1) {
    obj.minutes = Math.floor(_seconds / 60)
    _seconds = _seconds % (obj.minutes * 60)
  } else {
    obj.minutes = 0
  }
  // 秒
  if (_seconds >=0 ) {
    obj.seconds = _seconds
  }
  return obj
}
import { createSlice } from '@reduxjs/toolkit'
import { calcScore } from '../utils/calcScore'
export const moduleSlice = createSlice({
  name: 'score',
  initialState: {
    finishList: [],
    currentIndex: 0, /**逐题练习的索引 */
    scoreMap: {}, /**标准答案、分数 */
    indexProIdMap: {} /** problemId和index的映射表 */
  },
  reducers: {
    setFinishList: (state, action) => {
      state.finishList = action.payload
    },
    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload
    },
    setScoreMap: (state, action) => {
      state.scoreMap = action.payload
    },
    setIndexProIdMap: (state, action) => {
      state.indexProIdMap = action.payload
    },
    // 输入答案
    setScoreMapByAnswer: (state, action)=> {
      const currentIndex = state.currentIndex
      const scoreMap = state.scoreMap
      const indexProIdMap = state.indexProIdMap
      // 1. 获取对应的题目信息
      const propblemId = indexProIdMap[currentIndex]
      const quesInfo = scoreMap[propblemId]
      // 2. 设置答案
      console.log(action.payload, 'payload')
      const result = {...scoreMap, [propblemId]: {...quesInfo, myAnswer: action.payload}}
      if (!result.isFinish) {
        console.log(result, 'resultresult????')
        state.scoreMap = result
      }
    },
    // 打分
    checkScore(state, action) {
      const scoreMap = state.scoreMap
      const indexProIdMap = state.indexProIdMap
      // 1. 获取对应的题目信息
      const propblemId = indexProIdMap[action.payload]
      const quesInfo = scoreMap[propblemId]
      console.log(quesInfo.category, 'quesInfo.myAnswer!!!!')
      // 如果未做题, 不进行判分
      if (Array.isArray(quesInfo.myAnswer) && quesInfo.myAnswer.length === 0) {
        return
      } else if (['', undefined].includes(quesInfo.myAnswer)) {
        return
      }
      // 是否要全对才能得分
      let isNeedEvery = false
      // 选择题必须全对
      if (['single_choice', 'multiple_choice'].includes(quesInfo.category)) {
        isNeedEvery = true
      }
      const myScore = calcScore(quesInfo.myAnswer, quesInfo.answer, quesInfo.score, isNeedEvery)
      console.log(myScore, 'myScore????')
      if (!quesInfo.isFinish) {
        // 2. 设置分数
        const result = {...scoreMap, [propblemId]: {...quesInfo, myScore, isFinish: true}}
        console.log(result, 'result')
        state.scoreMap = result
      }
    }
  },
})

export const {
  setFinishList,
  setCurrentIndex,
  setScoreMap,
  setIndexProIdMap,
  setScoreMapByAnswer,
  checkScore
} = moduleSlice.actions;

export default moduleSlice.reducer;

import axios from 'axios'
import { message } from 'antd'
import { getToken } from '../utils/getToken'

const suffix = '/api/v1'

// export const baseURL = 'https://kgbackend.eliteu.xyz'
let _baseURL
if (window.location.host.includes('.xyz') || process.env.NODE_ENV === 'development') {
  _baseURL = 'https://kgbackend.eliteu.xyz'
} else {
  // _baseURL = 'https://quizbankadmin.aimaker.space'
  _baseURL = window.location.origin
}

export const baseURL = _baseURL

export const api = axios.create({
  // baseURL
  baseURL: baseURL + suffix,
  xsrfCookieName: 'exam_csrftoken'
})

api.interceptors.request.use(
    (request) => {
      const token = getToken()
      if (token) {
        request.headers.Authorization = `Bearer ${token}`
      }
      return request
    },
    (error) => {
      message.error(error.message)
      return Promise.reject(error)
    },
)

api.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      console.log(error.response.data.detail, 'errormsg')
      const errorMessage = error.response?.data?.message || error.response?.data?.msg || error.response?.data?.detail || error.message
      
      message.error('错误：' + errorMessage)
      
      if (error?.response?.status || error?.response?.status === 404) {
        console.log(404)
      }
      // 如果有数据但是没有权限访问，那么是 403
      // if (!error?.response?.status || error?.response?.status === 403) {
      //   window.location.href = '/403'
      // }

      if (error?.response?.status && error?.response?.status === 401) {
        // console.log('需要登录哦')
        // message.error(error.response.data?.detail)
        setTimeout(() => {
          window.localStorage.clear()
          window.location.href = '/login'
        }, 3000)
      }
      return Promise.resolve({ msg: errorMessage, response: error.response })
    },
)
import { createSlice } from '@reduxjs/toolkit'

export const moduleSlice = createSlice({
  name: 'choice',
  initialState: {
    choices: [],
    judges: [],
    blanks: [],
    setTextareas: []
  },
  reducers: {
    setChoices: (state, action) => {
      state.choices = action.payload
    },
    setJudges: (state, action) => {
      state.judges = action.payload
    },
    setBlanks: (state, action) => {
      state.blanks = action.payload
    },
    setTextareas: (state, action) => {
      state.textareas = action.payload
    }
  },
})

export const {
  setChoices,
  setJudges,
  setBlanks,
  setTextareas
} = moduleSlice.actions;

export default moduleSlice.reducer;

import { Table, Tabs, Modal } from "antd";
import { getRecord, me } from "../../request/user";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { secToMin, strToDateTime } from "../../utils/transferDate";
import { CATEGORY } from "../../utils/constant";
import { expiredExam } from "../../request/examination";
import { ALLOW_ANSWERS_SCORES } from "../../utils/constant";
import "./style.scss"
import { useNavigate } from "react-router-dom";
import { RandomLabel } from "../../components/randomLabel";
import { sortFn } from "../../utils/common";

const pageSize = 10

// // 记录的类型
// const CATEGORY = {
//   EXERCISE: 'exercise',
//   EXAM: 'exam'
// }

const Record = () => {
  const [modal, holderContext] = Modal.useModal()
  const [category, setCategory] = useState(CATEGORY.EXAM)
  const profile = useSelector(state => state.user.profile)
  const [data, setData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [sort, setSort] = useState(null)
  const [page_size, setPageSize] = useState(pageSize)
  const navigate = useNavigate()
  const tabs = [
    {
      label: '考试',
      key: CATEGORY.EXAM
    },
    {
      label: '练习',
      key: CATEGORY.EXERCISE
    }
  ]
  const handleChange = (category) => {
    setIsLoading(true)
    setCategory(category)
    setSort(null)
    setPageNum(1)
  }
  // 获取记录
  const [isLoading, setIsLoading] = useState(false)
  const getRecordXhr = () => {
    getRecord({username: profile.username, category, page: pageNum, page_size, sort}).then(res => {
      setIsLoading(false)
      if (res.status === 200) {
        setData(res.data)
      }
    })
  }
  useEffect(() => {
    getRecordXhr()
  }, [category, pageNum, page_size, sort])

  // 练习
  const exerciseColumns = [
    {
      title: '考试用时',
      key: 'answer_time',
      dataIndex: 'answer_time',
      width: 120,
      render(_, data) {
        return <>{secToMin(data.answer_time)}</>
      },
      sorter: true
    }
  ]
  // 考试
  const examColumns = [
    {
      title: '考试时长',
      key: 'paper__answer_time',
      dataIndex: 'paper__answer_time',
      width: 120,
      render(_, data) {
        if (data) {
          return <>{data.exam_info && secToMin(data.exam_info.answer_time)}</>
        }
      },
      sorter: true
    }, {
      title: '实际用时',
      key: 'answer_time',
      dataIndex: 'answer_time',
      width: 120,
      render(_, data) {
        return <>{secToMin(data.answer_time)}</>
      },
      sorter: true
    }, {
      title: '强制交卷',
      dataIndex: 'start_countdown',
      key: 'start_countdown',
      render (data) {
        if (data && data.exam_info) {
          if (data.exam_info.start_countdown) {
            return '是'
          }
        }
        return '否'
      },
      width: 120
    }
  ]
  let injectColumns = []
  // 练习
  if (category === CATEGORY.EXERCISE) {
    injectColumns = exerciseColumns
  // 考试
  } else if (category === CATEGORY.EXAM) {
    injectColumns = examColumns
  }
  const columns = [
    {
      title: '试卷编号',
      dataIndex: 'paper_id',
      key: 'paper_id',
      sorter: true,
      width: 200
    },
    {
      title: '试卷名称',
      dataIndex: 'paper_name',
      key: 'paper_name',
      width: 260,
      render(_, data) {
        return <RandomLabel bool={data.has_random_problem} name={data.paper_name}/>
      },
      sorter: true,
    },
    {
      title: '科目',
      dataIndex: 'paper_sub',
      key: 'paper_sub',
      sorter: true,
      width: 150
    },
    {
      title: '总分',
      dataIndex: 'total_score',
      key: 'total_score',
      sorter: true,
      width: 150
    },
    {
      title: '得分',
      dataIndex: 'score',
      key: 'score',
      width: 150,
      render(_, data) {
        // 交卷
        if (data.is_hand) {
          if (data.status === 1) {
            return '待阅卷'
          } else if (data.status === 2) {
            return '阅卷中'
          } else if (data.status === 3){
            return data.score
          }
        // 未交卷
        } else {
          return <div className="record__red">尚未交卷</div>
        }
      },
      sorter: true
    },{
      title: '开始时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render(_, data) {
        return <>
          {strToDateTime(data.created_at)}
        </>
      },
      sorter: true
    },
    {
      title: '交卷时间',
      dataIndex: 'submit_at',
      key: 'submit_at',
      width: 200,
      render(_, data) {
        return <>
          {strToDateTime(data.submit_at)}
        </>
      },
      sorter: true
    },
    ...injectColumns
    ,{
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render(_, data) {
        // 重做错题
        const Redo = () => {
          const total_score = +data.total_score
          const score = +data.score
          const isOk = !isNaN(score) && !isNaN(total_score)
          if (data.status === 3 && isOk && score < total_score) {
            return <div className="yl-table-btn" onClick={() => reform(data)}>错题重做</div>
          }
        }
        // 考试
        if (category === CATEGORY.EXAM) {
          // 已交卷
          if (data.is_hand) {
            // 允许查看答案/答卷
            if (data.exam_info && data.exam_info.allow_answers_scores === ALLOW_ANSWERS_SCORES.ALLOW) {
              return <div className="yl-table-actions">
                <div className="yl-table-btn" onClick={() => toPaperDetail(data)}>查看答卷</div>
                <Redo />
              </div>
            } else {
              return <div className="yl-table-actions">
                <div  className="yl-table-btn yl-table-btn--black">尚不允许查看答案和试卷</div>
              </div>
            }
            // 未交卷
          } else {
            return <div className="yl-table-btn" onClick={() => goOn(data)}>继续测试</div>
          }
        // 练习
        } else if (category === CATEGORY.EXERCISE) {
          // 已经提交
          if (data.is_hand) {
            return <div className="yl-table-actions">
              <div className="yl-table-btn" onClick={() => toPaperDetail(data)}>查看答卷</div>
              <Redo />
            </div>
          } else {
            // 没提交
            return <div className="yl-table-btn" onClick={() => goOn(data)}>继续测试</div>
          }
        }
      }
    },
    {
      title: '团队',
      dataIndex: 'examination__team__name',
      key: 'examination__team__name',
      width: 150,
      render(_, data) {
        return data.team
      }
    },
    {
      title: '阅卷人',
      dataIndex: 'consult_user',
      key: 'consult_user',
      width: 150
    }
  ];
  // 查看考卷
  const toPaperDetail = (data) => {
    let url
    // 练习
    if (CATEGORY.EXERCISE=== category) {
      url = window.location.origin + `/subject/exercise/answer/${data.id}`
    // 考试
    } else if (CATEGORY.EXAM === category) {
      url = window.location.origin + `/subject/exam/answer/${data.id}`
    }
    window.location.href = url
  }

  // 错题重做
  const reform = (data) => {
    let url
    // 练习
    if (category === CATEGORY.EXERCISE) {
      url = window.location.origin + `/subject/exercise/${data.id}/test/${data.id}?type=reform`
    // 考试
    } else if (category === CATEGORY.EXAM) {
      url = window.location.origin + `/subject/exam/${data.id}/${data.id}?type=reform&canCheckANS=${data.exam_info.allow_answers_scores}`
    }
    window.open(url)
  }

  // 继续测试
  const goOn = (data) => {
    // 考试
    if (category === CATEGORY.EXAM) {
      // 未提交
      if (!data.is_hand) {
        // 考试截止时间
        const finishTime = new Date(data.created_at).getTime() + data.exam_info.answer_time * 1000
        console.log(new Date(finishTime), '时间')
        // 当前时间
        const nowTime = new Date().getTime()
        // 强制交卷且时间到了
        if (data.exam_info.start_countdown && nowTime > finishTime) {
          const {destroy} = modal.info({
            title: '提示',
            content: '该试卷已经超时, 系统自动交卷中...',
            footer: null
          })
          expiredExam(data.id).then(res => {
            if (res.status === 200) {
              setTimeout(() => {
                getRecordXhr()
                destroy()
              }, 1000)
            }
          })
          // 可以继续测试
        } else {
          // 测试
          // const url = window.location.origin + `/subject/exam/${data.examination}/${data.examination}/`
          // window.open(url)
          navigate(`/subject/exam/${data.examination}/${data.examination}/`)
        }
      }
    // 练习
    } else if (category === CATEGORY.EXERCISE) {
      const url = window.location.origin + `/subject/exercise/${data.exercise}/test/${data.exercise}`
      window.open(url)
    }
  }

  // 翻页
  const handlePage = (...args) => {
    const sort = sortFn(args)
    if (sort !== false) {
      setSort(sort)
    }
    const page = args[0]
    setPageNum(page.current)
  }


  const showSizeChange = useCallback((page, page_size) => {
    setPageNum(page)
    setPageSize(page_size)
  }, [])

  return (
      <div className="record">
        <Tabs items={tabs} onChange={handleChange} />
        {!isLoading && <Table rowKey="id" scroll={{x: 1400}}
          pagination={
            { showSizeChanger: true,
              defaultPageSize: pageSize,
              total: data.count,
              current: pageNum,
              onShowSizeChange: showSizeChange
            }
          } onChange={handlePage}  columns={columns} dataSource={data.results}/>}
        { holderContext }
      </div>
  );
}

export default Record;
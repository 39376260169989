import {
  CrownFilled,
  CarryOutFilled,
  TabletFilled, 
  SettingFilled,
  DatabaseFilled
} from '@ant-design/icons';

const iconMap = [
    ['CrownFilled', <CrownFilled/>],
    ['CarryOutFilled', <CarryOutFilled/>],
    ['TabletFilled', <TabletFilled/>],
    ['SettingFilled', <SettingFilled/>],
    ['DatabaseFilled', <DatabaseFilled />]
]

export function replaceIconWithStr(obj, children = {}) {
  const newObj = children;

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        newObj[key] = replaceIconWithStr(obj[key], [])
      } else {
        newObj[key] = replaceIconWithStr(obj[key])
      }
    } else if (key === 'icon' && typeof obj[key] === 'string') {
      const target = iconMap.find(m => m[0] === obj[key])
      newObj[key] = target ? target[1] : ''
    } else {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}
import { useEffect, useRef, useState, useMemo } from 'react'
import { ProLayout, PageContainer, ProCard } from '@ant-design/pro-components'
import { routerSettings, ManagerType, StudentType } from './settings/ProComponents/ProLayoutSetting'
import { getRoutes, homeRoute, noMatchedRoute, redirectLogin } from './router'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Dropdown } from 'antd'
import { deepCopy } from './utils/devUtils'
import { replaceIconWithStr } from './utils/replaceIconWithStr'
import { handleFilterElement, defaultRoutes as routes } from './utils/routersFilter'
import { setGlobalRoutes, setRouterSetting, setPathname } from './store/routeSlice'
import { setIsLogin, setUserType, setProfile } from './store/userSlice'
import { login, me } from './request/user'
import './App.scss'
import { getSubjects, getSubjectTypes } from './request/subjects'
import { getUrlInfo, isMobile, linkToHar } from './utils/common'
import { getToken } from './utils/getToken'
import { useLogin } from './hooks'
// import { isMobile } from './utils/common.js'

function App () {
  const user = useSelector(state => state.user)
  const routerSetting = useSelector(state => state.route.routerSetting)
  const globalBread = useSelector(state => state.route.globalBread)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const pathname = useSelector(state => state.route.pathname)
  
  const [problemsChildRoutes, setProblemsChildRoutes] = useState([])
  const doLogin = useLogin()
  // 请求 /me 获取用户信息
  useEffect(() => {
    
    // 得到用户信息后
    const userType = window.localStorage.getItem('userType')
    // if (userType === StudentType) return

    /*getSubjects().then(({data}) => {
      if (!data.length) return
      const routerSetting = routerSettings.find(item => item.routeName === userType)
      const routes = [...routerSetting.route.routes[1].routes]
      data.forEach(item => {
        routes.push({
          path: `/publicProblemsCenter/page${item.id}`,
          name: item.name,
        })
      })
      const _routeSettings = {...routerSetting}
      _routeSettings.route.routes[1].routes = routes
      dispatch(setRouterSetting(_routeSettings))
      setProblemsChildRoutes(routes)
    }).catch(e => {
      console.log('e', e)
    })*/
    // if (userType) {
    //   dispatch(setUserType(userType))
    //   dispatch(setRouterSetting(routerSettings.find(item => item.routeName === userType)))
    // }
    
    // 信息验证后 这里进行自动登录
    if (window.localStorage.getItem('authed')) {
      dispatch(setIsLogin(true))
    } else {
      if (isMobile()) {
        const url = window.location.href
        const obj = getUrlInfo()
        if (obj.token) {
          login({ login_type: 'token', token: obj.token }).then(res => {
            window.localStorage.setItem('token', res.data.token)
            window.localStorage.setItem('authed', 'true')
            doLogin().then(res => {
              dispatch(setIsLogin(true))
            })
          })
        } else {
          const search = new URL(url).search
          let code = ''
          if (search) {
            let searchParams = new URLSearchParams(search)
            code = searchParams.get('_team_code')
          }
          console.log(code, 'code')
          if (process.env.NODE_ENV === 'production') {
            linkToHar({needHash: 'yes'}, encodeURIComponent(url), code)
          }
        }
      }
    }
    // // 如果是移动端
    // if (isMobile()) {
    
    // }
  }, [])
  
  const contentRoutes = useRef([])
  
  const globalRoutes = useSelector(
      state => state.route.routes,
      shallowEqual
  )

  /*useEffect(() => {
    // 有公共题库的子路由
    const problemsRoute = globalRoutes.find(item => item.path === '/publicProblemsCenter')
    if (problemsChildRoutes.length && problemsRoute) {
      console.log('globalRoutes', globalRoutes)
      // 进行 /publicProblemsCenter 的子路由添加
      // console.log('problemsRoute', problemsRoute)
      // const _problemsRoute = [...problemsRoute]
      // _problemsRoute.routes = problemsChildRoutes
      // console.log('problemsRoute', _problemsRoute)
    }
  }, [problemsChildRoutes, globalRoutes])*/
  
  
  // 路由部分
  /**
   * 路由基本逻辑: 
   * 1. 根据用户角色(userType)获取对应的路由列表, setGlobalRoutes设置全局路由
   * 2. globalRoutes改变后, 通过setRoutes修改defaultRoutes
   * 3. 将defaultRoutes返回给RouterRender进行渲染
   */
  const [defaultRoutes, setRoutes] = useState(
      handleFilterElement(deepCopy(routes))
  )
  
  // 渲染路由
  const RouterRender = () => useRoutes(defaultRoutes)
  
  // 监听路由表改变重新渲染
  useEffect(() => {
    // 登录
    if (user.isLogin) {
      // 登录成功后这里不需要再请求 me 接口，刷新时需重新请求
      requestMe.current && user.requestMe && me().then(({data}) => {
        dispatch(setProfile(data))
        
        const userType = window.localStorage.getItem('userType')
        dispatch(setUserType(userType))
        dispatch(setRouterSetting(routerSettings.find(item => item.routeName === userType)))
      }).catch(e => {
        console.log('e', e)
      })
      
      // deepCopy 深拷贝 state 数据 不能影响到 store 里的数据！
      // handleFilterElement 映射对应组件
      // handleEnd 将路由表嵌入默认路由表得到完整路由表 不一定需要

      // 登录前 * 为 Navigate 跳转到 login
      // 登录后将 * 路由更改为 notfound
      const currentRoutes = handleFilterElement(deepCopy(globalRoutes))
          .concat(noMatchedRoute)
      currentRoutes.unshift(homeRoute)
      contentRoutes.current = currentRoutes
          .map(item => item.path)
          .filter(item => item !== '*')
      setRoutes(currentRoutes)
    // 未登录
    } else {
      const routes = [...handleFilterElement(deepCopy(globalRoutes))]
      // 移动端不需要跳转登录页, 因为移动端登录页面为教学云
      if (!isMobile()) {
        routes[routes.length - 1] = redirectLogin[0]
      }
      setRoutes(routes)
    }
  }, [globalRoutes, user.isLogin])
  
  const requestMe = useRef(true)
  useEffect(() => {
    if (!user.userType) return
    // 根据角色设置路由
    const routes = getRoutes(user.userType)
    dispatch(setGlobalRoutes(routes))
    requestMe.current = false
  }, [user.userType])
  
  
  const [routerConfig, setRouterConfig] = useState()
  useEffect(() => {
    if (!Object.keys(routerSetting).length) return
    // routerSetting 更改时，将 icon 字符串映射成 icon 组件
    const obj = replaceIconWithStr(routerSetting)
    setRouterConfig(obj)
  }, [routerSetting])
  
  
  // 管理员退出登录
  const manageLogout = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  
  // 学生、教师返回个人中心
  const backUserCenter = () => {
    const origin = process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : window.location.host.includes('xyz')
        ? 'https://learn.dev.longan.eliteu.xyz'
        : 'https://course.aimaker.space/'
    const url = origin + '/account/home'
    window.location.href = url
    console.log('返回个人中心', url)
  }
  // 当前面包屑
  const currentCrumb = useMemo(() => {
    try {
      const _globalBread = JSON.parse(globalBread)
      const arr = _globalBread.map(item => {
        if (item.onClick) {
          // 如果有点击事件
          const originStr = item.onClick
          item.onClick = () => {
            eval(originStr)
          }
        }
        return item
      })
      return arr
    } catch(e) {
      return []
    }
  })
  const current = useLocation()
  const [title, setTitle] = useState('')
  return (
      <div id="App">
        {
          user.isLogin && !window.location.pathname.includes('subject')
              ?
              <ProLayout
                  {...routerConfig}
                  title='英荔 AI 客观题平台'
                  logo={null}
                  prefixCls='yl-prefix'
                  // siderMenuType='group'
                  menu={{
                    collapsedShowGroupTitle: true,
                  }}
                  location={{
                    pathname,
                  }}
                  pageTitleRender={() => {
                    // 根据面包屑判断
                    if (currentCrumb && currentCrumb[0]) {
                      dispatch(setPathname(pathname))
                      return currentCrumb[0].title
                    } else {
                      // 根据路由判断
                      if (current && current.pathname) {
                        let path = current.pathname
                        // 从面包屑点击返回, 生产环境可能会/结尾, 就会和路由的路径不同
                        if (current.pathname !== '/' && current.pathname.endsWith('/')) {
                          path = path.slice(0, -1)
                        }
                        if (!path) return ''
                        const regStr = path.replace(/\d+/g, ':\\w+')
                        const reg = new RegExp(regStr, 'g')
                        const result = defaultRoutes.find(item => {
                          return reg.exec(item.path)
                        })
                        if (result && result.title) {
                          setTitle(result.title)
                          return result.title
                        }
                      }
                      dispatch(setPathname(pathname))
                      return pathname
                    }
                  }}
                  menuItemRender={(item, dom) => (
                      <div
                          style={{userSelect: 'none'}}
                          onClick={() => {
                            if (window.location.pathname === item.path) return
                            
                            // 点击的是公共题库子路由
                            if (item.path.includes('/publicProblemsCenter/page')) {
                              
                            }
                            
                            contentRoutes.current.includes(item.path) && navigate(item.path)
                            dispatch(setPathname(item.path || '/'))
                          }}
                      >
                        {dom}
                      </div>
                  )}
                  splitMenus={true}
                  fixSiderbar={true}
                  layout='mix'
                  onMenuHeaderClick={(e) => console.log(e)}
                  avatarProps={{
                    size: 'small',
                    render () {
                      return (
                          <div className="avatarDropdown">
                            <Dropdown
                                trigger={['click']}
                                placement='bottom'
                                getPopupContainer={() => document.querySelector('.avatarDropdown')}
                                menu={{
                                  items: [{
                                    key: '1',
                                    label:  user.userType === ManagerType ? '退出' :'个人中心',
                                  }],
                                  className: 'personalCenter',
                                  onClick: user.userType === ManagerType ? manageLogout : backUserCenter
                                }}
                            >
                              <div>
                                <img src="https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg"
                                     alt=""
                                     className="avatar"
                                />
                                <span>{user.profile.name}</span>
                              </div>
                            </Dropdown>
                          </div>
                      )
                    }
                  }}
              >
                <PageContainer header={{title: title, breadcrumb: {items: currentCrumb}}}>
                  <ProCard style={{height: '100%'}}>
                    <RouterRender/>
                  </ProCard>
                </PageContainer>
              </ProLayout>
              :
              <RouterRender/>
        }
      </div>
  )
}

export default App

import "./style.scss"
import { getBacklogs, getUnreadCount, mark_all } from '../../request/user'
import { Tabs, Table, Modal } from "antd"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { TeacherType, ManagerType, StudentType } from "../../settings/ProComponents/ProLayoutSetting"
import { strToDateTime } from "../../utils/transferDate"
import { revokeExamination } from "../../request/examination"
import BackLog from "./backLog"
import Consult from "./consult"
import { CATEGORY } from "./common"
import { sortFn } from "../../utils/common"
import { pageSize } from "./common"


const Home = () => {
  const userType = useSelector(state => state && state.user && state.user.userType)
  const [page_size, setPageSize] = useState(pageSize)
  const [sort, setSort] = useState(null)
  const [category, setCategory] = useState(() => {
    // 如是老师, 则默认阅卷
    if (userType === TeacherType) {
      return CATEGORY.CONSULT
    } else {
      // 其他默认待办
      return CATEGORY.BACKLOG
    }
  })
  const [pageNum, setPageNum] = useState(1)
  const [data, setData] = useState([])
  const [unreadCount, setUnreadCount] = useState(false)

  // 读消息
  const getUnreadXhr = useCallback(() => {
    getUnreadCount().then(res => {
      if (res.status === 200) {
        setUnreadCount(res.data.count)
      }
    })
  }, [])
  // 初始化未读数据
  useEffect(() => {
    getUnreadXhr()
  }, [])
  const items = [
    {
      key: CATEGORY.CONSULT,
      label: '阅卷',
    },
    {
      key: CATEGORY.BACKLOG,
      label: <div className="todo__tab">
        {unreadCount > 0 && <div className="todo__unread"></div>}
        待办事宜
      </div>
    },
  ];
  // ==== table结构
  // ==== 切换tab
  const changeTab = (currentTab) => {
    setPageNum(1)
    // setPageSize(pageSize)
    setSort(null)
    setCategory(currentTab)
  }
  // ==== 获取数据
  const getBacklogsXhr = () => {
    setIsLoading(true)
    getBacklogs({ category, page: pageNum, page_size, sort }).then(res => {
      setIsLoading(false)
      if (res.status === 200) {
        setData(res.data)
        // 待办且有未读才会请求
        if (category === CATEGORY.BACKLOG && unreadCount > 0) {
          // 全部已读
          mark_all().then(res => {
            if (res.status === 200) {
              // 更新
              getUnreadXhr()
            }
          })
        }
      }
    })
  }

  // 翻页/切换tab
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getBacklogsXhr()
  }, [pageNum, category, page_size, sort])

  // // ==== 翻页
  // const handlePage = (e) => {
  //   setPageNum(e.current)
  // }

  // 翻页
  const handlePage = (...args) => {
    const sort = sortFn(args)
    if (sort !== false) {
      setSort(sort)
    }
    const page = args[0]
    setPageNum(page.current)
  }

  // 改变页数
  const changePageSize = (page, page_size) => {
    setPageNum(page)
    setPageSize(page_size)
  }

  return (
      <div className="todo">
        <div className="todo__tabs">
          {userType === TeacherType && <Tabs defaultActiveKey={1} items={items} onChange={changeTab} />}
        </div>
        {category === CATEGORY.BACKLOG ? 
          <BackLog update={getBacklogsXhr} onPageSizeChange={changePageSize} page_size={page_size} data={data} pageNum={pageNum} handlePage={handlePage} isLoading={isLoading}/>
          :
          <Consult update={getBacklogsXhr} onPageSizeChange={changePageSize} page_size={page_size} data={data} pageNum={pageNum} handlePage={handlePage} isLoading={isLoading}/>
        }
        
      </div>
  )
}

export default Home
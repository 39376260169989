import { configureStore } from '@reduxjs/toolkit'
import routesReducer from './routeSlice'
import userReducer from './userSlice'
import categoryReducer from './categorySlice'
import editorReducer from './editorSlice'
import choiceReducer from './choiceSlice'
import paperReducer from './paperSlice'
import finishReducer from './scoreSlice'
import randomReducer from './randomSlice'


export default configureStore({
  reducer: {
    route: routesReducer,
    user: userReducer,
    category: categoryReducer,
    editor: editorReducer,
    choice: choiceReducer,
    paper: paperReducer,
    score: finishReducer,
    random: randomReducer
  },
})

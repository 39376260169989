import { createSlice } from '@reduxjs/toolkit'

export const moduleSlice = createSlice({
  name: 'paper',
  initialState: {
    paperInfo: {
      id: 0,
      name: '',
      author: '',
      paperTime: '',
      updateAt: '',
      totalScore: 0
    },
    paperRecordData: {
      
    },
    paperScoreList: [],
    blankScoreList: [],
    seconds: 0
  },
  reducers: {
    setPaperInfo: (state, action) => {
      state.paperInfo = action.payload
    },
    setPaperRecordData: (state, action) => {
      state.paperRecordData = action.payload
    },
    setPaperScoreList: (state, action) => {
      state.paperScoreList = action.payload
    },
    setBlankScoreList: (state, action) => {
      state.blankScoreList = action.payload
    },
    setSeconds (state, action) {
      state.seconds = action.payload
    }
  },
})

export const {
  setPaperInfo,
  setPaperRecordData,
  setPaperScoreList,
  setBlankScoreList,
  setSeconds
} = moduleSlice.actions;

export default moduleSlice.reducer;

import { api } from './api'
import { getToken } from '../utils/getToken'
import { objToQuery } from '../utils/common'
export const login = (data) => {
  // let params = {
  //   login_type: 'password',
  //   password: 'whl123456',
  //   username: 'whl1'
  // }
  let params
  // 密码登录
  if (data.password) {
    params = {
      login_type: 'password',
      password: data.password,
      username: data.username
    }
  // 
  } else if (data.token) {
    params = {
      login_type: 'token',
      token: data.token,
    }
  }
  return api.post('/login/', params)
}


export const me = username => {
  // if (username) {
  //   return api.get(`/api/v1/users/${username}/`)
  // }
  return api.get('/users/me/', {
    // withCredentials: true
  })
}

// export const login = (store_no, store_password) => {
//   return api.post('/api/v1/store_login/', {
//     store_no: 'dongjun',
//     store_password: '20130530'
//   })
// }

// 获取临时令牌(用于在本地测试学生)
export const encrypt = username => {
  return api.get('​/users​/exam_encrypt_text​/', {
    // withCredentials: true
  })
}

export const getRecord = (params) => {
  const {username, ...options} = params
  if (!options.sort) {
    delete options.sort
  }
  const query = objToQuery(options)
    return api.get(`/users/${username}/record/?${query}`, {
  })
}

// 用户待办列表
export const getBacklogs = ({ category, page, page_size, sort}) => {
  let other = ''
  if (sort) {
    other = `&sort=${sort}`
  }
  return api.get(`/users/backlogs/?category=${category}&page=${page}&page_size=${page_size}${other}`)
}

// 用户待办批量撤回
export const revocation = (data) => {
  return api.get('/users/revocation/', data)
}

// 获取学生信息
export const getStudentInfo = (username) => {
  return api.get(`/users/${username}/student_info/`)
}

// 获取未读数
export const getUnreadCount = (username) => {
  return api.get(`/users/unread_count/`)
}

// 设为已读
export const mark_all = (username) => {
  return api.post(`/users/mark_all/`)
}
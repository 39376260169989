// 发送消息
export const sendStorageMsg = ({type, payload}) => {
  localStorage.setItem('@@' + type, JSON.stringify({
    type,
    payload,
    temp: Date.now()
  }))
}
// 监听
export const listenStorageMsg = (cb) => {
  function handler (e) {
    if (e && e.key && e.key.startsWith('@@')) {
      const newVal = e.newValue && JSON.parse(e.newValue)
      const oldVal = e.oldValue && JSON.parse(e.oldValue)
      cb && cb(newVal, oldVal)
    }
  }
  window.addEventListener('storage', handler)
  return () => window.removeEventListener('storage', handler)
}
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCategory, getSubjectTypes, deleteCategory } from '../../../request/subjects'
import { Button, Input, Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { setCategory } from '../../../store/categorySlice'
import { useNavigate } from 'react-router-dom'
import './style.scss'

const PaperType = ({ subjectId, subjectName, name, categores, setSubjectId, categoryId }) => {
  
  const navigate = useNavigate()
  const category = useSelector(state => state.category.category)
  const dispatch = useDispatch()
  
  const [paperTypeName, setPaperTypeName] = useState(name)
  const [activeCategoryId, setActiveCategoryId] = useState(categoryId)
  const confirmDeletePaperType = async (e, categoryId) => {
    e.stopPropagation()
    try {
      const { data } = await deleteCategory(subjectId, categoryId)
      // 删除之后如果没有对应的 activeSelect 则默认选中私有试卷
      if (categoryId === activeCategoryId) {
        setPaperTypeName(privatePaper)
        setActiveCategoryId(0)
      }
      console.log('delete success', data)
      await updateCategoryList()
    } catch (e) {
      console.log('delete error', e)
    }
    
  }
  
  const [showGlobalCategory, setShowGlobalCategory] = useState(false)
  const updateCategoryList = async () => {
    const { data: res } = await getSubjectTypes()
    if (res.length) {
      const categores = res.find(item => item.id === subjectId).categores
      dispatch(setCategory(categores))
      // 删除试卷分类时 如果试卷分类为 [] 则根据空试卷类型渲染
      !categores.length && setShowGlobalCategory(true)
    }
  }

  const [showAddCategory, setShowAddCategory] = useState(false)
  const addCategoryInput = useRef(null)
  const [inputPaperTypeName, setInputPaperTypeName] = useState('')
  const handleAddCategory = async (isAdd = true) => {
    setShowAddCategory(false)
    setInputPaperTypeName('')
    if (isAdd) {
      const name = addCategoryInput.current.input.value
      try {
        const { data } = await addCategory(subjectId, { name })
        if (data) {
          console.log('add success', data)
          // 请求新的数据
          await updateCategoryList()
        }
      } catch (e) {
        console.log('添加试卷分类失败', e)
      }
    }
  }
  
  const selectCategory = (id, name) => {
    setPaperTypeName(name)
    setActiveCategoryId(id)
  }
  
  const addNewPaper = () => {
    const url = window.location.origin + `/subject/${subjectId}/new/1`
    window.open(url)
  }
  
  const activeColor = '#97a4f5'
  const privatePaper = '我私有的试卷'

  // 创建一个包含指定个数且相同元素的数组
  const fillElements = Array(20).fill(0)

  return (
      <div id='paperType'>
        <Button onClick={() => setSubjectId(-1)}>返回</Button>
        
        <div className='paperTypeTitle'>
          <span>公共题库中心 > 科目：{subjectName}</span>
          <div className='buttons'>
            <Button onClick={() => setShowAddCategory(true)}>添加试卷分类</Button>
            <Button type='primary'
                    style={{marginLeft: '10px'}}
                    onClick={() => addNewPaper()}
            >录入新试卷</Button>
          </div>
        </div>

        <Modal title='添加试卷分类'
               open={showAddCategory}
               onOk={() => handleAddCategory(true)}
               onCancel={() => handleAddCategory(false)}
               width={320}
        >
          <Input style={{margin: '20px 0'}}
                 placeholder='请输入试卷分类名称'
                 ref={addCategoryInput}
                 value={inputPaperTypeName}
                 onChange={(e) => setInputPaperTypeName(e.target.value)}
                 onPressEnter={handleAddCategory}
          />
        </Modal>
        
        <div className='line'></div>
        
        <div className='labelBox'>
          
          <div className='labelButton'
               onClick={() => selectCategory(0, privatePaper)}
               style={ activeCategoryId === 0 ? { backgroundColor: activeColor } : {} }
          >
            我私有的试卷
          </div>
          
          {/* 全局的 categoryList 不存在就用父组件传来的默认值 */}
          { (category.length || showGlobalCategory ? category : categores)?.map(item => (
              <div key={item.id}
                   onClick={() => selectCategory(item.id, item.name)}
                   className='labelButton'
                   style={ item.id === activeCategoryId ? { backgroundColor: activeColor } : {} }
              >
                <span className='name'>{item.name}</span>
                <CloseOutlined onClick={(e) => confirmDeletePaperType(e, item.id)}
                               style={{color: 'red', marginLeft: 10}}
                />
              </div>
          ))}
          {fillElements.map((_,i) => <i key={i}/>)}
        </div>
        
        <h1>{ paperTypeName || name } PaperType Page</h1>
      </div>
  )
}

export default PaperType
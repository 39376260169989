import { useEffect, useRef, useState, memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCategory } from '../../store/categorySlice'
import { setRouterSetting } from '../../store/routeSlice'
import { Table } from 'antd'
import { getSubjectTypes } from '../../request/subjects'
import PaperType from './PaperType'
import './style.scss'
import { sortFn } from '../../utils/common'

const ProblemsCenter = () => {
  
  const [subjects, setSubjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [sort, setSort] = useState(null)
  
  const navigate = useNavigate()
  
  const dispatch = useDispatch()
  const routerSetting = useSelector(state => state.route.routerSetting)
  const categoryList = useSelector(state => state.category.category)
  
  // 多次挂载 —> 先挂载 再打印 globalRoutes
  useEffect(() => {
    // 请求科目及其科目下的试卷分类
    getSubjectTypes({sort}).then(({data}) => {
      data && setLoading(false)
      if (data.length) {
        // 改造 data -> dataSource
        const subjects = data.map(item => {
          return {
            key: item.id,
            subject: (
                <span className='subjectName'>{item.name}</span>
            ),
            paperTypes: 
                <PaperTypeName categores={item.categores} 
                               subjectId={item.id}
                               subjectName={item.name}
                />
          }
        })
        setSubjects(subjects)

       /* const routes = [...routerSetting.route.routes[1].routes]

        routes.push({
          path: '/todo',
          name: '待办',
          icon: 'CarryOutFilled',
          component: '../pages/Todo',
        })

        console.log('routes', routes)

        const routeSettings2 = {...routerSetting}

        routeSettings2.route.routes[1].routes.concat(routes)

        console.log('routeSettings2', routeSettings2)*/

        // dispatch(setRouterSetting(routeSettings2))

        data.forEach(item => {
          // routes.route.routes[1].routes.push({
          //   name: item.name
          // })
        })

      } else {
        // 暂无数据
      }
    })
  }, [categoryList, sort])
  
  const [paperTypeName, setPaperTypeName] = useState('')
  const [categores, setCategores] = useState([])
  const [subjectId, setSubjectId] = useState(-1)
  const [subjectName, setSubjectName] = useState('')
  const [categoryId, setCategoryId] = useState(0)
  const enterPaperTypePage = (subjectId, subjectName, name, categores, categoryId) => {
    navigate(`/problemsList/${subjectId}/${categoryId}?category=${name}`)
    // setCategoryId(categoryId)
    // setSubjectId(subjectId)
    // setSubjectName(subjectName)
    // setPaperTypeName(name)
    // setCategores(categores)
  }
  
  const PaperTypeName = ({ subjectId, categores, subjectName }) => {
    return (
        <div className='subjects'>
          {
            categores.map(item => {
              return <span key={item.id} 
                className='category'
                onClick={() => enterPaperTypePage(
                    subjectId,
                    subjectName,
                    item.name,
                    categores,
                    item.id
                )}>{item.name}</span>
            })
          }
        </div>
    )
  }

  const columns = [
    {
      title: '科目',
      dataIndex: 'subject',
      key: 'subject',
      width: 200
    },
    {
      title: '试卷分类',
      dataIndex: 'paperTypes',
      key: 'paperTypes',
    },
  ];

  // // 翻页
  // const handlePage = (...args) => {
  //   const sort = sortFn(args)
  //   if (sort !== false) {
  //     setSort(sort)
  //   }
  // }
  return (
      <div id='publicProblemCenter'>
        {!~subjectId
            ?
            <Table dataSource={subjects}
                   columns={columns}
                // showHeader={false}
                   bordered={true}
                   loading={loading}
                    pagination={{
                      showSizeChanger: true,
                      defaultPageSize: 20
                    }}
              />
            : 
            <PaperType name={paperTypeName}
                       categores={categores}
                       setSubjectId={setSubjectId}
                       // setPaperName={setPaperTypeName}
                       subjectId={subjectId}
                       subjectName={subjectName}
                       categoryId={categoryId}
            ></PaperType>
        }
      </div>
  )
}

export default memo(ProblemsCenter)
import { Table } from "antd"
import { strToDateTime } from "../../utils/transferDate"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RandomLabel } from "../../components/randomLabel"
import { pageSize } from "./common"

const Consult = ({data, handlePage, pageNum, isLoading, onPageSizeChange, page_size}) => {
  const navigate = useNavigate()
  // 阅卷
  const toConsult = (data) => {
    console.log(data, 'data')
    navigate(`/subject/exam/answer/${data.obj_id}?type=reCheck`)
  }
  const profile = useSelector(state => state.user.profile)
  // 列
  const columns = [
    {
      title: '试卷编号',
      key: 'paper_id',
      dataIndex: 'paper_id',
      sorter: true,
      width: 150
    },
    {
      title: '试卷名称',
      key: 'name',
      dataIndex: 'name',
      width: 150,
      render(_, data) {
        if (data) {
          return <RandomLabel bool={data.has_random_problem} name={data.name}/>
        }
      },
      sorter: true
    },
    {
      title: '科目',
      key: 'subjects',
      dataIndex: 'subjects',
      width: 100,
      sorter: true
    },
    {
      title: '总分',
      key: 'total_score',
      dataIndex: 'total_score',
      sorter: true
    },
    {
      title: '题量',
      key: 'problem_num',
      dataIndex: 'problem_num',
      sorter: true
    },
    {
      title: '考生',
      key: 'student_name',
      dataIndex: 'student_name',
      render(_, data) {
        if (data) {
          return profile.id === data.student_id ? '[我自己]' : data.student_name
        }
      },
      sorter: true
    },
    {
      title: '操作',
      dataIndex: 'actions',
      render(_, data) {
        return (
          <>
            <div className='yl-table-btn' onClick={() => toConsult(data)}>阅卷</div>
          </>
        )
      }
    },
    {
      title: '交卷时间',
      key: 'sub_time',
      dataIndex: 'sub_time',
      width: 200,
      render(_, data) {
        if (data) {
          return strToDateTime(data.sub_time)
        }
      },
      sorter: true
    },
    {
      title: '来自团队',
      width: 150,
      key: 'team_name',
      dataIndex: 'team_name',
      sorter: true
    },
  ]

  // 改变page_size
  function changePageSize(page, page_size) {
    onPageSizeChange(page, page_size)
  }
  return <>
    <Table
      onChange={handlePage}
      pagination={{
        defaultPageSize: pageSize,
        total: data.count,
        current: pageNum,
        showSizeChanger: true,
        onShowSizeChange: changePageSize,
      }}
      rowKey="id" scroll={{y: 300}}
      loading={isLoading}
      columns={columns}
      dataSource={data.results}
    />
  </>
}

export default Consult
import { createSlice } from '@reduxjs/toolkit'

export const moduleSlice = createSlice({
  name: 'user',
  initialState: {
    isLogin: false,
    userType: '',
    requestMe: true, // 是否需要请求 me 接口
    profile: {}
  },
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload
    },
    setProfile: (state, action) => {
      state.profile = action.payload
    },
    setRequestMe: (state, action) => {
      state.requestMe = action.payload
    }
  },
})

export const {
  setIsLogin,
  setUserType,
  setProfile,
  setRequestMe
} = moduleSlice.actions;

export default moduleSlice.reducer;

import { createSlice } from '@reduxjs/toolkit'

export const moduleSlice = createSlice({
  name: 'random',
  initialState: {
    randomData: [],
  },
  reducers: {
    setRandomData: (state, action) => {
      const key = action.payload.key
      const value = action.payload.value
      const problemNumber = action.payload.problemNumber
      const isHasNumber = ![undefined, null, '', ' ', 'null', 'undefined', -1].includes(problemNumber)
      // 有key和problemNumber
      if (key && isHasNumber) {
        const _randomData = state.randomData.map((item, index) => {
          if (isHasNumber && index === (problemNumber - 1)) {
            return {...item, ...{[key]: value}}
          } else {
            return item
          }
        })
        state.randomData = _randomData
      // 无key有problemNumber
      } else if (isHasNumber) {
        console.log('???????')
        // 新增
        if (problemNumber >= state.randomData.length) {
          state.randomData = [...state.randomData, action.payload.value]
        // 修改某个
        } else {
          const _randomData = state.randomData.map((item, index) => {
            if (index === (problemNumber - 1)) {
              return action.payload
            }
          })
          state.randomData = _randomData
        }
      // 正常修改
      } else {
        state.randomData = action.payload
      }
    }
  },
})

export const {
  setRandomData,
} = moduleSlice.actions;

export default moduleSlice.reducer;

export const alphabets = Array.from(
  { length: 26 },
  (_, i) => String.fromCharCode(i + 65)
)

// 获取翻页数据的序号
export const getIndex = ({pageNum, pageSize, index}) => {
  return (pageNum - 1) * pageSize + index + 1
}

export const objToQuery = (obj) => {
  let query = ''
  Object.entries(obj).forEach(([key, value]) => {
    if (!(key === 'sort' && !value)) {
      query += `${key}=${value}&`
    }
  })
  return query.slice(0, -1)
}

// 下载excel
export const downloadExcel = (data, fileName) => {
  console.log(data)
  const blob = new Blob([data], {type: "application/vnd.ms-excel"})
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  link.click()
  window.URL.revokeObjectURL(link.href)
}

// 获取数据类型
export const getDataType = (data) => {
  try {
    const str = Object.prototype.toString(data)
    return str.slice(7, -1)
  } catch (e) {
    return null
  }
}

// 转为数字
export const toNumber = (num) => {
  if (isNaN(num)) {
    return 0
  } else {
    return +num
  }
}

// 判断是否是移动端
export const isMobile = () => {
  try {
    // 用户信息
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent)
  } catch (e) {
    console.log('isMobile判断失败')
    return false
  }
}

// 排序方法
export function sortFn (args) {
  const action = args[3].action
  if (action === 'sort') {
    const field = args[2].field
    const order = args[2].order
    console.log(field, order, 'sortFn')
    if (order === 'descend') {
      return '-' + field
    } else if (order === 'ascend') {
      return field
    }
    return null
  } else {
    return false
  }
}

// 页面最初访问的url
const ORIGINURL = 'origin_url_from_first_time'
export const setOriginUrl = () => {
  sessionStorage.setItem(ORIGINURL, window.location.href)
}

export const getOriginUrl = () => {
  sessionStorage.getItem(ORIGINURL)
}

// 教学云地址
export const harmonize = () => {
  let url;
  if (process.env.NODE_ENV === 'production') {
    if (window.location.host.indexOf('.aimaker.') !== -1) {
      // 生产服
      url = 'https://course.aimaker.space';
    } else {
      url = 'https://learn.dev.longan.eliteu.xyz';
    }
  } else {
    url = 'http://localhost:3000'
    // url = 'https://m.learn.test.longan.eliteu.xyz';
  }
  return url;
};

// 跳转教学云
export const linkToHar = (searchObj, originUrl, code = '') => {
  let _search = ''
  // 如果存在search部分
  if (searchObj) {
    _search = `?${obj2Query(searchObj)}`
  }
  // 拼接
  let url = `${harmonize()}/login${code ? '/' + code : ''}${_search}`
  if (originUrl) {
    url += (_search ? '&' : '?') + 'redirect=' + originUrl
  }
  window.location.href = url;
}

function obj2Query(obj) {
  let query = ''
  Object.entries(obj).forEach(([key, value]) => {
    query += `${key}=${value}&`
  })
  return query.slice(0, -1)
}

export const getUrlInfo = () => {
  const url = window.location.href
  const reg = /([^?&=]+)=([^&]*)/g
  const arr = url.match(reg)
  let obj = {}
  if (arr) {
    arr.forEach(item => {
      let itemArr = item.split('=')
      obj[itemArr[0]] = itemArr[1]
    })
  }
  return obj
}
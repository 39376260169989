// 记录的类型
export const CATEGORY = {
  EXERCISE: 'exercise',
  EXAM: 'exam'
}

// 判分模式
export const CONSULTKEYS = {
  SYS: 1,
  TEACHER: 2
}

// 考试
export const CONSULTTYPE = {
  [CONSULTKEYS.SYS]: "系统阅卷",
  [CONSULTKEYS.TEACHER]: "教师阅卷"
}

// 练习
export const EXERCISE_CONSULTTYPE = {
  [CONSULTKEYS.SYS]: "系统阅卷",
  [CONSULTKEYS.TEACHER]: "考生自评分"
}

// 题型
export const PAPERPATTERN = {
  objectivity: {
    text: '全客观题',
    score: '系统评分',
    consult_type: CONSULTKEYS.SYS
  },
  subjectivity: {
    text: '含有主观题',
    score: '考生自评分',
    consult_type: CONSULTKEYS.TEACHER
  },
  has_random: {
    text: '具有随机选题功能的智能型试卷',
    score: '考生自评分',
    consult_type: CONSULTKEYS.TEACHER
  },
}

// 通知类型
export const CATEGORY_CHOICES = 
{
  exam: '考试通知',
  view_grades: '查看成绩通知'
}

export const STATUS_CHOICE = {
  "0": "待审核",
  "2": "审核通过",
  "3": "审核不通过",
}

// 是否允许查看答案/答卷
export const ALLOW_ANSWERS_SCORES = {
  NO_ALLOW: 1,
  ALLOW: 2
}

// 练习/考试
export const TYPES = {
  EXERCISE: 'exercise',
  EXAM: 'exam'
}

// 阅卷状态
export const PAPERSTATUS = {
  TOBE: 1,
  DOING: 2,
  DONE: 3,
}

// 阅卷状态
/**
 *  (1, "待阅卷"),
    (2, "阅卷中"),
    (3, "已阅卷"),
 */
export const CONSULT_STATUS = {
  TODO: 1,
  DOING: 2,
  DONE: 3
}

// 图片前缀
export const secretImageUrl =
  'https://longan-iot.oss-cn-shenzhen.aliyuncs.com/longan-iot/students/';
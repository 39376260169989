import Todo from '../pages/Todo'
import Group from '../pages/Group'
import Record from '../pages/Record'
import PublicProblemsCenter from '../pages/ProblemsCenter'
import { Navigate } from 'react-router-dom'
import { ManagerType, StudentType, TeacherType } from '../settings/ProComponents/ProLayoutSetting'
import { lazyLoad } from '../utils/routersFilter'

export const noMatchedRoute = [
  {
    path: '*',
    element: lazyLoad('NotFound')
  }
]

export const homeRoute = {
  path: '/',
  element: <Navigate to="/todo" replace={true}/>
}

export const redirectLogin = [
  {
    path: '*',
    element: <Navigate to="/login" replace={true}/>
  }
]

export const getRoutes = role => {
  switch (role) {
    case StudentType:
      return [
        {
          path:'/todo',
          element: 'Todo',
          title: '待办'
        },
        {
          path:'/group',
          element: 'Group',
          title: '团队'
        },
        {
          path: '/record',
          element: 'Record',
          title: '记录'
        },
        {
          path: '/exercise/:teamId',
          element: 'Exercise',
          title: '练习'
        },
        {
          path: '/exam/:teamId',
          element: 'Exam',
          title: '考试'
        },
        {
          path: '/subject/consult/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/:subjectId/preview/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/exercise/:exerciseId/test/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/exam/:examId/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/exam/:examId/',
          element: 'EditPaper'
        },
        {
          path: '/subject/exercise/:recordId',
          element: 'EditPaper'
        },
        {
          path: '/subject/exercise/answer/:paramsRecord',
          element: 'EditPaper'
        },
        {
          path: '/subject/exam/answer/:paramsRecord',
          element: 'EditPaper'
        },
        {
          path: '/subject/onebyone/:exerciseId',
          element: 'EditPaper'
        },
        {
          path: '/exercisePaperResult/:id',
          element: 'ExercisePaperResult'
        },
        {
          path: '/subject/reform/:recordId',
          element: 'EditPaper'
        },
        {
          path: '/examPaperResult/:teamId/:id',
          element: 'ExamPaperResult',
          title: '答卷与成绩'
        },
      ]
    
    case TeacherType:
      return [
        {
          path:'/todo',
          element: 'Todo',
          title: '待办'
        },
        {
          path:'/group',
          element: 'Group',
          title: '团队'
        },
        {
          path: '/problemsCenter',
          element: 'ProblemsCenter',
          title: '公共题库中心'
        },
        {
          path: '/problemsList/:subjectId/:categoryId',
          element: 'ProblemsList',
          title: '试卷分类'
        },
        {
          path: '/subject/:subjectId/preview/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/:subjectId/answer/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/studentlist/:id',
          element: 'StudentList',
          title: '学员列表'
        },
        {
          path: '/donePaper/',
          element: 'DonePaper',
          title: '考过的答卷'
        },
        {
          path: '/exercise/:teamId',
          element: 'Exercise',
          title: '练习管理'
        },
        {
          path: '/exam/:teamId',
          element: 'Exam',
          title: '考试管理'
        },
        {
          path: '/exercisePaperResult/:id',
          element: 'ExercisePaperResult'
        },
        {
          path: '/subject/exercise/answer/:paramsRecord',
          element: 'EditPaper'
        },
        {
          path: '/subject/exercise/:exerciseId/test/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/consult/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/examPaperResult/:teamId/:id',
          element: 'ExamPaperResult',
          title: '答卷与成绩'
        },
        {
          path: '/subject/exam/:examId/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/exam/answer/:paramsRecord',
          element: 'EditPaper'
        },
        {
          path: '/papers/teamExam/scoreAnalysis',
          element: 'ScoreAnalysis'
        },
        {
          path: '/papers/teamExercise/scoreAnalysis',
          element: 'ScoreAnalysis'
        },
        {
          path: '/subject/onebyone/:exerciseId',
          element: 'EditPaper'
        },
      ]
    
    case ManagerType:
      return [
        {
          path:'/todo',
          element: 'Todo',
          title: '待办'
        },
        {
          path: '/subject/:subjectId/new/',
          element: 'EditPaper'
        },
        {
          path: '/subject/:subjectId/edit/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/edit/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/:subjectId/preview/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/subject/:subjectId/answer/:paperId',
          element: 'EditPaper'
        },
        {
          path: '/problemsCenter',
          element: 'ProblemsCenter',
          title: '公共题库中心'
        },
        {
          path: '/problemsList/:subjectId/:categoryId',
          element: 'ProblemsList',
          title: '试卷分类'
        },
        {
          path: '/exerciseStat/:paperId',
          element: 'ExerciseStat',
        },
        {
          path: '/examStat/:paperId',
          element: 'ExamStat',
        },
        {
          path: '/manageSubject',
          element: 'ManageSubject',
          title: '科目管理',
        },
        { path: '/papers/:subjectId',
          element: 'ExamList',
          title: '科目分类'
        },
        {
          path: '/subject/exercise/answer/:paramsRecord',
          element: 'EditPaper'
        },
        {
          path: '/subject/exam/answer/:paramsRecord',
          element: 'EditPaper'
        },
        {
          path: '/papers/paperExam/scoreAnalysis',
          element: 'ScoreAnalysis'
        },
        {
          path: '/papers/paperExercise/scoreAnalysis',
          element: 'ScoreAnalysis'
        }
      ]
    // default:
    //   return []
  }
}

import { api } from './api'

export const getTeam = ({page, page_size, search, sort}) => {
  let url = `/teams/?page=${page}&page_size=${page_size}`
  if (search) {
    url = `${url}&search=${search}`
  }
  if (sort) {
    url = `${url}&sort=${sort}`
  }
  return api.get(url)
}

// 学生列表
export const getStudents = ({id, page, page_size, search, sort}) => {
  let url = `/teams/${id}/students/?page=${page}&page_size=${page_size}`
  if (search) {
    url = `${url}&search=${search}`
  }
  if (sort) {
    url = `${url}&sort=${sort}`
  }
  return api.get(url)
}

// 同步数据
export const syncData = (data) => {
  return api.post(`/teams/sync_data/`, data)
}

// 考过的试卷
export const getStudentStatus = ({username, page, page_size}) => {
  return api.get(`/team_student/${username}/status?page=${page}&page_size=${page_size}`)
}

// 团队学员信息
export const getStudentInfo = (username) => {
  return api.get(`/team_student/${username}/`)
}

// 团队详情
export const getTeamDetail = (id) => {
  return api.get(`/teams/${id}/`)
}
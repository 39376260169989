import { createSlice } from '@reduxjs/toolkit'

export const moduleSlice = createSlice({
  name: 'category',
  initialState: {
   category: []
  },
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    
  },
})

export const {
  setCategory,
} = moduleSlice.actions;

export default moduleSlice.reducer;

import { createSlice } from '@reduxjs/toolkit'

export const moduleSlice = createSlice({
  name: 'editor',
  initialState: {
    focus: false,
    listType: '',
    problemNum: 1, // 题干编号
    tooltipClass: '',
    status: 'new'
  },
  reducers: {
    setFocus: (state, action) => {
      state.focus = action.payload
    },
    setListType: (state, action) => {
      state.listType = action.payload
    },
    setProblemNum: (state, action) => {
      state.problemNum = action.payload
    },
    setTooltipClass: (state, action) => {
      state.tooltipClass = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    }
  },
})

export const {
  setFocus,
  setListType,
  setProblemNum,
  setTooltipClass,
  setStatus
} = moduleSlice.actions;

export default moduleSlice.reducer;

import { api } from './api';
/**
 * @template T
 * @typedef {import('axios').AxiosResponse<T>} AxiosResponse
 */
export const createExamination = (data) => {
  return api.post(`/examination/`, data);
};

// 提交考试
export const submitExamination = (id, data) => {
  return api.put(`/examination_record/${id}/`, data);
};

// 获取考试答案
/**
 * 
 * @param {string} id 
 * @returns {Promise<AxiosResponse<ExamAnswer>>}
 */
export const getExamAnswer = (id) => {
  return api.get(`/examination_record/${id}/`);
};

// 获取考试
export const getExamination = ({ teamId, page, page_size }) => {
  return api.get(
    `/examination/?team_id=${teamId}&page=${page}&page_size=${page_size}`,
  );
};

// 获取考试
export const getExamDetail = (id) => {
  return api.get(`/examination/${id}/`);
};

// 删除试卷
export const delExamRecord = (id) => {
  return api.delete(`/examination_record/${id}/`);
};

// 获取测试id
export const getExamDo = (id) => {
  return api.get(`/examination/${id}/do/`);
};

// 获取考试成绩
export const getExaminationScore = ({ id, page, page_size, sort }) => {
  let other = ''
  if (sort) {
    other = `&sort=${sort}`
  }
  return api.get(
    `/examination/${id}/score/?page=${page}&page_size=${page_size}${other}`,
  );
};

// 设置考试
export const setExamination = (id, data) => {
  return api.put(`/examination/${id}/`, data);
};

// 删除考试
export const delExamination = (id) => {
  return api.delete(`/examination/${id}/`);
};

// 通知考试
export const informExamination = (id, data) => {
  return api.post(`/examination/${id}/inform/`, data);
};

// 撤销考试通知
export const revokeExamination = (id, data) => {
  return api.delete(`/examination/${id}/inform/`, data);
};

// 阅卷
export const saveExamCon = (id, data) => {
  return api.post(`/examination_record/${id}/consult/`, data);
};

// 试卷超时
export const expiredExam = (id, data) => {
  return api.post(`/examination_record/${id}/expired/`, data);
};

// 重新考试
export const reformExam = (id, data) => {
  return api.get(`/examination_record/${id}/reform/`, data);
};

// 提交重新考试
export const submitReformExam = (id, data) => {
  return api.put(`/examination_record/${id}/reform/`, data);
};

// 获取重新考试答案
/**
 *
 * @param {string} id
 * @returns {Promise<AxiosResponse<ExamReform>>}
 */
export const getReformExamAns = (id) => {
  return api.post(`/examination_record/${id}/reform/`);
};

// 成绩分析
export const getExamAnalysis = (id, {sort}) => {
  let other = ''
  if (sort) {
    other = `?sort=${sort}`
  }
  return api.get(`/examination/${id}/score_analysis/${other}`);
};

// 通知考生查看成绩
export const informViewGrades = (id) => {
  return api.post(`/examination/${id}/view_grades/`);
};

// 查阅练习
export const viewExamination = (id) => {
  return api.get(`/examination/${id}/view/`);
};

// 是否考试
export const check = (id) => {
  return api.get(`/examination/${id}/check/`)
}
